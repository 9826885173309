import React from 'react';
import arrow from '../assets/icons/arroworange.png';
// import artistImage from '../assets/images/Vladlen.jpg';

const About = () => {
  return (
    <section className='about' id='about'>
      {/* <h2 className='title'>DESPRE</h2> */}
      <div className='content'>
        <div className='images'>
          <div className='hidden'>
            <img src={arrow} className='arrow' />
          </div>
          {/* <img src={artistImage} className='artist' /> */}
        </div>
        <p className='text'>
          Primele două ediții ale expoziției LSR organizate de Ateneul Național
          din Iași au generat un interes real din partea artiștilor și a
          instituțiilor participante, în cadrul evenimentului fiind reunite
          opere ale sculpturii moderne și contemporane românești, atingându-se,
          astfel, un important grad de notorietate la nivel național și
          internațional.
        </p>
        <p className='text'>
          Cea de-a treia ediție, organizată în contextul Zilei Culturii
          Naționale și dedicată lui Constantin Brâncuși, se dorește să capete o
          amploare firească, prin includerea în cadrul evenimentului a unor
          spații expoziționale noi și atragerea unor parteneri din mediul privat
          (colecționari / galerii ș.a.), pentru a acoperi o arie vastă a
          orașului Iași, astfel încât, în perioada Februarie - Martie, zone
          importante ale orașului să fie destinate exclusiv sculpturii.
        </p>
        <p className='text-spec'>
          <strong> Manager proiect: </strong>
          <br />
          Sculptor / Dr. Vladlen BABCINEȚCHI <br />
          <strong>Curatori:</strong> <br />
          Critic de artă / Dr. Maria BILAȘEVSCHI <br />
          Scriitor / Critic de artă Grigore ILISEI
          <br />
          Critic de artă / Prof. Ioan RĂDUCEA
          <br />
          <strong>Invitați speciali:</strong> <br />
          Președinte UAPR / Prof. univ / Dr. Petru LUCACI
          <br />
          Director al MNA al Moldovei Tudor ZBÂRNEA
          <br />
          Manager al MNA Constanța / Dr. Lelia PÂRVAN
          <br />
          Sculptor / Prof. univ. Napoleon TIRON
          <br />
          Istoric de artă Ovidiu POPESCU
          <br />
        </p>
      </div>
    </section>
  );
};

export default About;
